<template>
  <div class="prestart">
    <Prestart />
  </div>
</template>

<script>
import { Prestart } from 'exam-service-web-lib';

export default {
  name: 'PrestartPage',
  components: {
    Prestart,
  },
};
</script>
